<template>
  <thead>
    <tr>
      <template v-if="!changeLog">
        <th :class="[modifiedRangeData.isActive ? 'column-icon-88' : 'column-icon']"></th>
        <th class="width-46"><input type="checkbox" v-model="selectAll" :value="true" /></th>
      </template>
      <template v-if="changeLog">
        <th></th>
        <th @click="changeSorting(16)" class="sort"><p :class="getClassSorting(16)">Действие</p></th>
        <th @click="changeSorting(17)" class="sort"><p :class="getClassSorting(17)">Дата изменения</p></th>
        <th @click="changeSorting(18)" class="sort"><p :class="getClassSorting(18)">Пользователь</p></th>
      </template>
      <template v-for="column in tableHeader" :key="column.id">
        <th
          v-if="changeLog ? true : column.visible"
          @click="changeSorting(column.id)"
          :class="['sort', column.width ? `width-${column.width}` : '']"
        >
          <p :class="getClassSorting(column.id)" v-html="column.name"></p>
        </th>
      </template>
    </tr>
    <tr v-if="!modifiedRangeData.isActive || changeLog">
      <th></th>
      <th></th>
      <th v-if="changeLog"></th>
      <th v-if="changeLog"></th>
      <template v-for="column in tableHeader" :key="column.id">
        <th v-if="changeLog ? true : column.visible">
          <template v-if="column.id == tableIdColumns.year">
            <div class="horizontally-date">
              <dateYearComponent
                v-model="filter.yearFrom"
                placeholder="от"
                modClass="filter-small"
                minimumViewDate="year"
                dateFormatProps="y"
                :upperLimit="upperLimitYearFrom"
              />
              <dateYearComponent
                v-model="filter.yearTo"
                placeholder="до"
                modClass="filter-small"
                minimumViewDate="year"
                dateFormatProps="y"
                :upperLimit="upperLimitTo"
                :lowerLimit="lowerLimitYearTo"
              />
            </div>
          </template>
          <template v-if="column.id == tableIdColumns.organName">
            <SelectMultiComponent
              v-model="filter.organId"
              :options="measureOrganProtectedMarketList"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.decisionDate">
            <div class="horizontally-date">
              <dateComponent
                v-model="filter.decisionDateFrom"
                modClass="filter-small"
                :upperLimit="upperLimitDecisionDateFrom"
              />
              <dateComponent
                v-model="filter.decisionDateTo"
                modClass="filter-small"
                :upperLimit="upperLimitTo"
                :lowerLimit="lowerLimitDecisionDateTo"
              />
            </div>
          </template>
          <template v-if="column.id == tableIdColumns.decisionNumber">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              type="number"
              v-model="filter.decisionNumber"
              clearButton
              @onClear="filter.decisionNumber = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.initiator">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.initiator"
              clearButton
              @onClear="filter.initiator = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.tnvedName">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.tnvedAlias"
              clearButton
              @onClear="filter.tnvedAlias = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.tnvedNameBefore">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.tnvedBeforeCode"
              clearButton
              @onClear="filter.tnvedBeforeCode = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.rateBefore">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.rateBefore"
              clearButton
              @onClear="filter.rateBefore = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.tnvedNameAfter">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.tnvedAfterCode"
              clearButton
              @onClear="filter.tnvedAfterCode = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.rateAfter">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.rateAfter"
              clearButton
              @onClear="filter.rateAfter = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.applyDate">
            <div class="horizontally-date">
              <dateComponent
                v-model="filter.applyDateFrom"
                modClass="filter-small"
                :upperLimit="upperLimitApplyDateFrom"
              />
              <dateComponent
                v-model="filter.applyDateTo"
                modClass="filter-small"
                :upperLimit="upperLimitTo"
                :lowerLimit="lowerLimitApplyDateTo"
              />
            </div>
          </template>
          <template v-if="column.id == tableIdColumns.comment">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.comment"
              clearButton
              @onClear="filter.comment = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.industryNames">
            <SelectMultiComponent
              v-model="filter.industryId"
              :options="industries"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.prolongation">
            <ComponentInput
              placeholder="Введите"
              mod="filter-small"
              v-model="filter.prolongation"
              clearButton
              @onClear="filter.prolongation = ''"
            />
          </template>
          <template v-if="column.id == tableIdColumns.isActive">
            <SelectMultiComponent
              v-model="filter.isActive"
              :options="getStatusOptions"
              placeholder="(Все)"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
          <template v-if="column.id == tableIdColumns.moveDirection">
            <SelectMultiComponent
              v-model="filter.moveDirectionId"
              :options="moveDirections"
              placeholder="Выберите"
              modifier="filter-small"
              classSelect="visible-clear"
            />
          </template>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script>
  import { debounce } from 'lodash';
  import moment from 'moment';

  import ComponentInput from '@/common/components/ComponentInput';
  import dateComponent from '@/common/components/dateComponent.vue';
  import dateYearComponent from '@/common/components/dateYearComponent.vue';
  import SelectMultiComponent from '@/common/components/SelectMultiComponent.vue';
  import Constants from '@/common/constants';

  import duplicateMethods from '../../mixins/duplicateMethods';
  import { TABLE_ID_SINGLE } from '../../utils/constants';

  export default {
    name: 'HeaderTable',
    emits: ['changeSorting', 'changeFilter', 'changeSelectAll'],
    mixins: [duplicateMethods],
    components: {
      ComponentInput,
      dateComponent,
      dateYearComponent,
      SelectMultiComponent,
    },
    props: {
      modifiedRangeData: {
        type: Object,
        default: () => {
          return {
            isActive: false,
            showModal: false,
            filter: null,
            filterText: null,
          };
        },
      },
      tableHeader: {
        type: Array,
        default: () => [],
      },
      changeLog: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        sortColumn: 0,
        sortOrder: 0,
        filter: {
          yearFrom: '',
          yearTo: '',
          organId: '',
          decisionDateFrom: '',
          decisionDateTo: '',
          decisionNumber: '',
          initiator: '',
          tnvedAlias: '',
          tnvedBeforeCode: '',
          rateBefore: '',
          tnvedAfterCode: '',
          rateAfter: '',
          applyDateFrom: '',
          applyDateTo: '',
          comment: '',
          industryId: '',
          prolongation: '',
          isActive: '',
          moveDirectionId: '',
        },
        selectAll: false,
        upperLimitTo: new Date(),
        tableIdColumns: TABLE_ID_SINGLE,
      };
    },
    mounted() {
      if (this.changeLog) {
        this.sortColumn = 17;
        this.sortOrder = 1;
      }
    },
    computed: {
      upperLimitYearFrom() {
        if (this.filter.yearTo) {
          return moment(this.filter.yearTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitYearTo() {
        return moment(this.filter.yearFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },

      upperLimitDecisionDateFrom() {
        if (this.filter.decisionDateTo) {
          return moment(this.filter.decisionDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitDecisionDateTo() {
        return moment(this.filter.decisionDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },

      upperLimitApplyDateFrom() {
        if (this.filter.applyDateTo) {
          return moment(this.filter.applyDateTo, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
        }
        return new Date();
      },
      lowerLimitApplyDateTo() {
        return moment(this.filter.applyDateFrom, Constants.DATE_FORMATS['YYYY-MM-DD']).toDate();
      },
    },
    methods: {
      getClassSorting(id) {
        if (this.sortColumn == id) {
          if (this.sortOrder == 0) return this.sortClassName.ascending;
          else if (this.sortOrder == 1) return this.sortClassName.descending;
        }
        return this.sortClassName.default;
      },

      changeSorting(id) {
        if (this.sortColumn == id) this.sortOrder = this.sortOrder == 0 ? 1 : 0;
        else this.sortOrder = 0;
        this.sortColumn = id;

        this.$emit('changeSorting', {
          sortColumn: this.sortColumn,
          sortOrder: this.sortOrder,
        });
      },

      changeFilter: debounce(function () {
        this.$emit('changeFilter', this.filter);
      }, 500),

      /**
       * @public
       * Вызывается в родительском компоненте через ref
       */
      clearFilter() {
        this.filter.yearFrom = '';
        this.filter.yearTo = '';
        this.filter.organId = '';
        this.filter.decisionDateFrom = '';
        this.filter.decisionDateTo = '';
        this.filter.decisionNumber = '';
        this.filter.initiator = '';
        this.filter.tnvedAlias = '';
        this.filter.tnvedBeforeCode = '';
        this.filter.rateBefore = '';
        this.filter.tnvedAfterCode = '';
        this.filter.rateAfter = '';
        this.filter.applyDateFrom = '';
        this.filter.applyDateTo = '';
        this.filter.comment = '';
        this.filter.industryId = '';
        this.filter.prolongation = '';
        this.filter.isActive = '';
        this.filter.moveDirectionId = '';
      },
    },
    watch: {
      filter: {
        handler() {
          this.changeFilter();
        },
        deep: true,
      },

      selectAll(value) {
        this.$emit('changeSelectAll', value);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/table/table-base.scss';
  @import '../../styles/index.scss';
</style>
